<template>
    <div>
        <div class="row section-header"  v-if="!embed">
            <div class="col-md-9 section-title">
                <h3>Attività svolte</h3>
            </div>

            <div class="col-md-3 text-right" style="">

            </div>
        </div>
        <div class="top-content ">

        </div>

        <div class="content card">
            <div class="index opened">
                <div v-if="embed">
                    <div class="row">
                        <div class="col-md-6"><h2>Attività svolte</h2></div>
                        <div class="col-md-6 text-right"><b-button variant="primary" @click="printPdf()">Anteprima libretto</b-button></div>
                    </div>
                    <hr>
                </div>

                <b-form-group id="drivingLicenseId" label="Patente" label-for="drivingLicenseId" description="" v-if="!programId">
                    <b-form-select v-model="drivingLicenseId" @change="retrieveActivityLogs">
                        <option value="-1">TUTTE</option>
                        <option v-for="license in drivingLicenses" v-bind:value="license.id" :key="license.id">
                            {{ license.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <div v-for="program in programs"
                     :key="program.id"
                     class=""
                     style="margin-bottom: 25px"
                >
                    <div class="container">
                        <b-row>
                            <b-col sm="10">
                                <h5>{{ program.name }} ({{ programTypes.find(item => item.value == program.type).name }}) <b-badge pill style="font-size:0.8rem;" v-if="program.edulab == 1">EduLab</b-badge></h5>
                            </b-col>
                            <b-col sm="2" class="text-right">
                                <b-icon icon="house" v-if="program.type == 'lesson' && program.edulab == 0" />
                                &nbsp;
                                <b-icon icon="cloud" v-if="program.type == 'lesson' && program.edulab == 0" />

                                <img src="@/assets/edulab-icon.png" v-if="program.edulab == 1" width="25" />
                            </b-col>
                        </b-row>
                    </div>


                    <!--
                    <b-progress :max="program.countMandatory" height="1.5rem" variant="success" >
                        <b-progress-bar :value="countDistinctActivityCompleted(program.id)">
                            <span>Progress: <strong>{{ countDistinctActivityCompleted(program.id) }} / {{ program.countMandatory }}</strong></span>
                        </b-progress-bar>
                    </b-progress>
                    -->
                    <hr>
                    <div class="container" v-if="program.type == 'lesson'">

                        <topic-tree-view
                            v-for="topic in program.topics"
                            :topic="topic"
                            :key="topic.id"
                            :topics="topic.children"
                            :label="topic.title"
                            :depth="0"
                            :activity-logs="activityLogs"
                            :program="program"
                        />
                        <b-row > <!-- v-if="program.presenceType == 'elearning'" -->
                            <b-col class=" font-weight-bold">Tempo totale (in ore)</b-col>
                            <b-col class="text-right font-weight-bold">{{ edulabTime(program.id) }}</b-col>
                        </b-row>
                    </div>
                    <div class="container" v-if="program.type == 'practice'">
                        <practice-topic-tree-view
                            v-for="topic in program.topics"
                            :topic="topic"
                            :key="topic.id"
                            :topics="topic.children"
                            :label="topic.title"
                            :depth="0"
                            :activityLogs="activityLogs"
                            :showAllTopics="true"
                        />
                        <b-row>
                            <b-col class=" font-weight-bold">Tempo totale di guida (in ore)</b-col>
                            <b-col class="text-right font-weight-bold">{{ practiceTime(program.id) }}</b-col>
                        </b-row>

                    </div>

                </div>

                <div v-if="programs.length == 0">
                    <p><i>Nessuna attività svolta</i></p>
                </div>

            </div>

        </div>


    </div>

</template>

<script>
import ActivityLogDataService from "@/components/activity_log/ActivityLogDataService";
import TopicTreeView from "@/components/activity_log/TopicTreeView";
import PracticeTopicTreeView from "@/components/practice/PracticeTopicTreeView";
import TopicDataService from "@/components/topic/TopicDataService";
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";
import moment from "moment";

export default {
    name: "activity-log-index",
    components: {TopicTreeView, PracticeTopicTreeView},
    data() {
        return {
            programs: [],
            programTypes: [{name: 'Teoria', value: 'lesson'}, {name: 'Guida', value: 'practice'}],
            activityLogs: [],
            currentProgramId: -1,
            drivingLicenses: [],
            drivingLicenseId: -1
        };
    },
    props: ['studentId','programId','embed'],
    methods: {
        retrieveActivityLogs() {
            this.programs = [];

            let id = this.studentId ||  this.$store.state.user.profileId;
            if (this.programId) {
                ActivityLogDataService.getByProgramAndStudent(id, this.programId)
                    .then(response => {
                        this.activityLogs = response.data;
                        let currentProgramId = -1;
                        for (let activityLog of this.activityLogs) {
                            // recupero tutti i programmi presenti nelle attività svolte
                            if (currentProgramId != activityLog.programId) {
                                currentProgramId = activityLog.programId
                                this.retrieveTopics(activityLog);
                            }
                        }
                        // order programs
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else if (this.drivingLicenseId > -1) {
                ActivityLogDataService.getAllByStudentAndDrivingLicense(id, this.drivingLicenseId)
                    .then(response => {
                        this.activityLogs = response.data;
                        let currentProgramId = -1;
                        for (let activityLog of this.activityLogs) {
                            // recupero tutti i programmi presenti nelle attività svolte
                            if (currentProgramId != activityLog.programId) {
                                currentProgramId = activityLog.programId
                                this.retrieveTopics(activityLog);
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                ActivityLogDataService.getAllByStudent(id)
                    .then(response => {
                        this.activityLogs = response.data;
                        let currentProgramId = -1;
                        for (let activityLog of this.activityLogs) {
                            // recupero tutti i programmi presenti nelle attività svolte
                            if (currentProgramId != activityLog.programId) {
                                currentProgramId = activityLog.programId
                                this.retrieveTopics(activityLog);
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },
        retrieveTopics(activityLog) {
            TopicDataService.getAll(activityLog.programId)
                .then(response => {
                    function countMandatoriesRecursive(topics) {
                        let i = 0;
                        for (const topic of topics) {
                            if (topic.mandatory === true) i++;
                            if (topic.children) i += countMandatoriesRecursive(topic.children);
                        }
                        return i;
                    }
                    const countMandatories = countMandatoriesRecursive(response.data.rows);
                    let program = {
                        id: activityLog.programId,
                        name: activityLog.program.name,
                        type: activityLog.program.type,
                        edulab: activityLog.program.edulab,
                        topics: response.data.rows,
                        count: response.data.count,
                        countMandatory: countMandatories
                    }
                    this.programs.push(program);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        countDistinctActivityCompleted(programId) {
            const completed = this.activityLogs.filter(x => x.programId === programId && x.status === 'Completed' && x.topic.mandatory);
            const uniqueCompleted = [...new Set(completed.map(item => item.topicId))]; // [ 'A', 'B']
            return uniqueCompleted.length;
        },

        practiceTime(programId) {
            let seconds = 0;
            for (let activity of this.activityLogs) {
                if (activity.programId == programId) {
                    const duration = moment(activity.practice.dateEnd).diff(moment(activity.practice.dateStart), 'seconds');
                    seconds += duration;
                }
            }
            return moment.utc(seconds*1000).format('HH:mm:ss');
        },

        edulabTime(programId) {
            let seconds = 0;
            for (let activity of this.activityLogs) {
                if (activity.programId == programId && activity.edulabLog !== null) {
                    const duration = moment(activity.edulabLog.dateEnd).diff(moment(activity.edulabLog.dateStart), 'seconds');
                    seconds += duration;
                }
            }
            return moment.utc(seconds*1000).format('HH:mm:ss');
        },

        watchHandler() {
            this.programs = [];
            this.activityLogs = [];
            this.retrieveActivityLogs();
        },
        printPdf() {
            let routeData = this.$router.resolve({path: 'booklet/'+this.studentId,  query: {print: '1'}});
            window.open(routeData.href, '_blank');
        }
    },
    mounted() {
        this.retrieveActivityLogs();
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        this.$watch('programId', this.watchHandler);
    },
    /*
    watch: {
        $props: {
            handler() {
                this.programs = [];
                this.activityLogs = [];
                this.retrieveActivityLogs();
            },
            deep: true,
            immediate: true,
        }
    }

     */
};
</script>

<style>
.progress {
    border-radius: 1em;
}
</style>
